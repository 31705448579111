<template>
	<div class="container">
		<div class="title text-c">
			浙工大志愿者之家用户协议
		</div>
		<div class="content">
			【首部及导言】
		</div>
		<div class="content">
			欢迎您使用浙工大志愿者之家小程序！
		</div>
		<div class="content">
			为了更好地为您提供服务，请您仔细阅读《浙工大志愿者之家用户协议》（以下简称“本协议”）及相关协议、业务规则。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款、法律适用和管辖条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。限制、免除责任条款及法律适用和管辖条款可能以加粗、下划线形式提示您注意。本协议是您（以下也可用“用户”来指代您）与就使用“浙工大志愿者之家”产品所达成的协议。
		</div>
        <div class="content">
			如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。
浙工大志愿者之家小程序上已注册用户均可参与志愿服务活动，相关志愿服务活动的信息真实性由活动组织机构负责。
		</div>
		<div class="title">
			一、【协议的范围】
		</div>
		<div class="content">
			1.1【协议适用主体范围】
		</div>
		<div class="content">
			本协议约定了浙江工业大学和用户之间就使用“浙工大志愿者之家”产品及服务事宜发生的权利义务关系。
		</div>
        <div class="content">
			1.2【用户】指在本平台注册并使用相关服务的，有意愿参与志愿服务活动的个人，在本协议中更多地称为“您”。
		</div>
        <div class="content">
			1.3 本平台服务涉及的其他相关主体及其定义如下：
		</div>
        <div class="content">
			1.3.1【活动组织机构】指在本平台上自主发布志愿服务活动及活动详情，招募用户作为志愿者参与提供志愿服务，并组织志愿服务活动开展的个人团体、企业、机构及其他社会组织。
		</div>
        <div class="content">
			1.3.2【组织管理员】指活动组织机构指定的，在平台上对志愿服务活动及参与活动的志愿者进行管理的用户。
		</div>
        <div class="content">
			1.3.3【平台管理员】指浙江工业大学对本平台进行管理的人员。
		</div>
		<div class="title">
			二、关于本服务
		</div>
		<div class="content">
			2.1 本服务的内容
		</div>
		<div class="content">
			本服务内容是指腾讯公益向您提供的通过本平台参与志愿服务活动的相关服务。
		</div>
		<div class="content">
			2.2 本服务的形式
		</div>
		<div class="content">
			2.2.1 您使用本服务需要访问浙工大志愿者小程序微信小程序等服务载体，您仅可为访问或使用本服务的目的而使用这些网站、软件及服务。
		</div>
		<div class="content">
			2.2.2 您在注册成为本平台用户或作为用户登录本平台时，同意授权本平台获取您的学生相关信息；您同意向本平台提供您的真实姓名、身份证号、手机号码、性别、电子邮箱等个人信息，用于向活动组织机构提供以筛选报名志愿者；您同意向本平台提供您的公益时长、活动汇报、活动照片等信息，用于向活动组织机构提供，以实现平台、活动组织机构对志愿者及活动的管理与记录。
		</div>
		<div class="content">
			2.3 本服务许可的范围
		</div>
		<div class="content">
			2.3.1 本平台给予用户一项个人的、不可转让及非排他性的许可，以使用本服务。您仅能以公益、非商业、合法之目的使用本服务。
		</div>
		<div class="content">
			2.3.2 本条及本协议其他条款未明示授权的其他一切权利仍由本平台保留，您在行使这些权利时须另外取得本平台的书面许可。本平台若未行使前述任何权利，并不构成对该权利的放弃。
		</div>
        <div class="title">
			三、用户的权利义务
		</div>
		<div class="content">
			3.1 您理解并同意，为了向您提供有效的服务，本服务会利用您终端设备的处理器和带宽等资源。本服务使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。
		</div>
		<div class="content">
			3.2 您在使用本平台中接入的某一特定服务时，该特定服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”），您在使用该项服务前请阅读并同意相关的单独协议。
		</div>
        <div class="content">
			3.3 您确认在使用本服务的意思表示出自您的真实意愿；同时您对使用本服务过程中发出的指令的真实性及有效性承担全部责任，该指令视为您的真实意思表示且不可变更或撤销。您承诺，本平台依照您的指令进行操作的一切风险均由您承担。
		</div>
		<div class="content">
			3.4 您在参与志愿服务活动前，应当事先完整阅读、充分了解志愿服务活动的相关内容、风险提示及特殊资质、能力要求等详细信息，选择与您个人能力、身体健康状况相匹配的志愿服务活动。您理解并同意，您参与志愿服务活动应当遵守活动组织机构制定的服务规则或您与活动组织机构间的其他相关约定，您参与志愿服务活动所产生的一切纠纷及责任应由您与活动组织机构自行沟通解决，与本平台无关，本平台不参与您和您报名参加的志愿服务活动对应的活动组织机构之间的纠纷。
		</div>
		<div class="content">
			3.5 您应当自行对发布内容的合法性、真实性负责。活动组织机构或其他个人通过本平台发布的任何信息（包括但不限于本平台活动组织机构发布的信息），本平台并不能保证其完全真实或完全准确，也不代表本平台已证实其描述或赞同其观点，仅供参考，您应当自行进行理性分析和判断。
		</div>
		<div class="content">
			3.6 您保证在本平台中存储的数据信息均系通过合法渠道获得并有权在本平台上进行存储，上述数据信息的收集、使用等过程不违法任何法律法规且不侵犯任何第三方权利以及对您有约束力的任何法律文件
		</div>
		<div class="content">
			3.7 您理解并同意本平台将会尽其合理努力保障您在本服务中的数据存储安全，但是，本平台并不能就此提供完全保证，包括但不限于以下情形：
		</div>
		<div class="content">
			3.7.1 本平台不对您在本服务中相关数据的删除或储存失败负责；
		</div>
		<div class="content">
			3.7.2 如果您停止使用本服务或服务被终止或取消，本平台可以从服务器上永久地删除您的数据。服务停止、终止或取消后，本平台没有义务向您返还任何数据。
		</div>
		<div class="content">
			3.7.3 您在使用本服务时，须自行承担如下来自本平台不可掌控的风险内容，包括但不限于：
		</div>
		<div class="content">
			3.7.3.1 由于不可抗拒因素可能引起的个人信息丢失、泄漏等风险；
		</div>
		<div class="content">
			3.7.3.2 您必须选择与所安装终端设备相匹配的服务版本，否则，由于服务与终端设备型号不相匹配所导致的任何问题或损害，均由用户自行承担；
		</div>
		<div class="content">
			3.7.3.3 您在使用本服务访问第三方网站时，因第三方网站及相关内容所可能导致的风险，由用户自行承担；
		</div>
		<div class="content">
			3.7.3.4 由于无线网络信号不稳定、无线网络带宽小等原因，所引起的登录失败、资料同步不完整、页面打开速度慢等风险。
		</div>
		<div class="content">
			3.8 为便于对志愿者及志愿服务活动的管理与记录，活动组织机构及其管理人员可能对您上传的个人资料、活动图片、活动汇报、公益时长等内容进行查看、删除、编辑等管理操作，您理解并同意，因此产生的一切争议和责任与本平台无关，应由您与活动组织机构及其管理人员自行沟通解决。
		</div>
		<div class="content">
			3.9 您理解并同意，本平台为履行国家相关法律法规、政策文件等规定的义务或符合相关监管机关的要求，可以依法将您在本平台产生的志愿服务信息（包括个人基本信息、志愿服务情况等）同步录入至有关部门指定的志愿服务系统。
		</div>
		<div class="content">
			3.10 第三方产品和服务
		</div>
		<div class="content">
			3.10.1 您在使用本服务中由第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。本平台和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。
		</div>
		<div class="content">
			3.10.2 本服务可能会调用第三方系统或者通过第三方支持您的使用或访问，相关服务结果及内容由该第三方提供，本平台不保证第三方提供服务及内容的安全性、准确性、有效性及不存在其他不确定的风险，由此若引发的任何争议及损害，与本平台无关，本平台不承担任何责任。
		</div>
		<div class="content">
			3.11 本平台可能会制定、修改普遍适用的规定、制度、要求、流程、办法、指引等规范性文件（简称“平台规则”）。除特别说明外，平台规则一经发布或更新则立即生效，适用于包括您在内的全体平台用户。如您不同意遵守相关平台规则，请立即停止使用本平台相关服务。如您继续使用本平台服务，视为您同意遵守相关平台规则。
		</div>
        <div class="title">
			四、用户行为规范
		</div>
		<div class="content">
			4.1 账号使用规范
		</div>
		<div class="content">
			4.1.1 您保证对您、您授权或指定人员使用账号的行为承担所有责任。
		</div>
		<div class="content">
			4.1.2 本平台有权对您提交的各类信息资料进行调查与核实，并在调查处理完毕后向您的账号及设备发送相关处理结果。但并不因为本平台的调查和审核，而减轻或免除您对其所提交的信息的完整性、真实性、合法性的保证责任，由此产生的一切责任和后果仍由您单独承担。如本平台对您提供的信息的完整性、真实性、合法性存在任何疑问时，本平台有权发出通知要求您作出解释、改正。
		</div>
		<div class="content">
			4.1.3 如本平台发现或收到他人举报您存在违反本协议规定或相关法律法规、政策的，有权向您核实有关情况，限制您在本平台的活动，发出警告通知以及终止向您提供本服务。
		</div>
		<div class="content">
            4.2 信息内容规范
		</div>
        <div class="content">
            4.2.1 本条所述信息内容是指您使用本平台和服务过程中所制作、复制、发布、传播的任何内容，包括但不限于注册信息，或文字、图片等上传的内容信息，以及其他使用本平台及服务所产生的内容。
		</div>
        <div class="content">
            4.2.2 您理解并同意，本平台致力于为用户提供文明健康、规范有序的网络环境，您不得利用本服务制作、复制、发布、传播如下干扰本平台正常运作以及违反我国法律法规、侵犯第三方合法权益的内容，包括但不限于：
		</div>
        <div class="content">
            4.2.2.1 发布、传送、传播、储存违反国家法律法规禁止的内容：
		</div>
        <div class="content">
            （1）违反宪法确定的基本原则的；
		</div>
        <div class="content">
            （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
		</div>
        <div class="content">
            （3）损害国家荣誉和利益的；
		</div>
        <div class="content">
            （4）煽动民族仇恨、民族歧视，破坏民族团结的；
		</div>
        <div class="content">
            （5）破坏国家宗教政策，宣扬邪教和封建迷信的；
		</div>
        <div class="content">
            （6）散布谣言，扰乱社会秩序，破坏社会稳定的；
		</div>
        <div class="content">
            （7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
		</div>
        <div class="content">
            （8）侮辱或者诽谤他人，侵害他人合法权益的；
		</div>
        <div class="content">
            （9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；
		</div>
        <div class="content">
            （10）以非法民间组织名义活动的；
		</div>
        <div class="content">
            （11）不符合遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的；
		</div>
        <div class="content">
            （12）含有法律、行政法规禁止的其他内容的。
		</div>
        <div class="content">
            4.2.2.2 发布、传送、传播侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
		</div>
        <div class="content">
            4.2.2.3 侵犯他人隐私、个人信息或资料的；
		</div>
        <div class="content">
            4.2.2.4 发表、传送、传播骚扰、广告信息及垃圾信息或含有任何性或性暗示的；
		</div>
        <div class="content">
            4.2.2.5 其他违反法律法规、政策及公序良俗、社会公德或干扰本平台正常运营和侵犯其他用户或第三方合法权益内容的信息。
		</div>
        <div class="content">
            4.3 服务运营规范
		</div>
        <div class="content">
            4.3.1 您发布或传播的内容应有合法来源，相关内容为您所有或您已获得权利人的授权。您同意本平台可为履行本协议或提供本服务的目的而使用您发布或传播的内容。
		</div>
        <div class="content">
            4.3.2 您使用本服务时不得违反国家法律法规、侵害他人合法权益，包括但不限于您不得利用本服务与活动组织机构串通伪造公益时长，不得利用本服务发布违法违规信息侵害他人的合法权益等。您理解并同意，为了防止出现此类违法违规情形，本平台有权对您利用本服务发布的信息以及在本服务中的操作进行核查并按本平台制定的相关规则进行处理。同时您理解并同意，如您被他人投诉侵权或您投诉他人侵权，本平台有权将争议中相关方的主体、联系方式、投诉相关内容等必要信息提供给其他争议方或相关部门，以便及时解决投诉纠纷，保护他人合法权益。
		</div>
        <div class="content">
            4.3.3 您不得冒用他人资料（包括不限于姓名、图片、昵称等），如果您提供的资料不准确，不真实，不合法，或者冒用他人资料，本平台保留直接修改资料或者结束您使用本平台各项服务的权利。
		</div>
        <div class="content">
            4.4 用户禁止行为
		</div>
        <div class="content">
            您使用本服务过程种不得从事下列行为：
		</div>
        <div class="content">
            4.4.1 对本服务进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本服务的源代码；
		</div>
        <div class="content">
            4.4.2 对腾讯公益拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
		</div>
        <div class="content">
            4.4.3 通过非腾讯公益开发、授权的第三方软件、插件、外挂、系统，登录或使用腾讯公益提供的服务，或制作、发布、传播上述工具；
		</div>
        <div class="content">
            4.4.4 自行或者授权他人、第三方软件对本服务及其组件、模块、数据进行干扰；
		</div>
        <div class="content">
            4.4.5 删除本软件及其副本上关于著作权的信息；
		</div>
        <div class="content">
            4.4.6 对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非腾讯公益经授权的第三方工具/服务接入本软件和相关系统；
		</div>
        <div class="content">
            4.4.7 通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
		</div>
        <div class="content">
            4.4.8 诱导其他用户运行含有计算机病毒等破坏性程序的内容或点击含有计算机病毒等破坏性程序链接页面的；
		</div>
        <div class="content">
            4.4.9 您理解并同意，若您违反前述约定、本协议或相关法规政策，本平台有权不经您同意而直接终止向用户提供本软件和服务。若由于本平台按照前述约定终止提供本软件和服务而对您产生影响或任何损失的，您同意不追究本平台的任何责任或不向本平台主张任何权利。
		</div>
        <div class="content">
            4.4.10 本服务中部分子服务项有可能由第三方提供，您在使用此类第三方服务前，应仔细阅读该第三方提供的服务协议、声明、提示等法律文件和重要信息，如您不同意前述内容，请立即停止使用相关服务。
		</div>
        <div class="content">
            4.5 违约处理
		</div>
        <div class="content">
            4.5.1 如果本平台发现或收到他人举报或投诉您违反本协议约定的，本平台有权不经通知对相关内容进行删除、屏蔽或断开链接等措施，并视行为情节对违规用户处以包括但不限于警告、限制或禁止使用部分或全部功能，及公告处理结果。
		</div>
        <div class="content">
            4.5.2 您理解并同意，本平台有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。
		</div>
        <div class="content">
            4.5.3 您理解并同意，因用户违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；本平台因此遭受损失的，您也应当一并赔偿。
		</div>
        <div class="title">
			五、服务的更新
		</div>
        <div class="content">
            5.1 为了改善用户体验、完善服务内容，本平台将不断努力开发新的服务，并不时提供更新（这些更新可能会采取服务替换、修改、功能强化、版本升级等形式）。
		</div>
        <div class="content">
            5.2 为了保证本服务的安全性和功能的一致性，本平台有权不经向您特别通知而对本服务进行更新，或者对本服务的部分功能效果进行改变或限制。
		</div>
        <div class="title">
            六、用户个人信息保护
		</div>
        <div class="content">
            6.1 保护用户个人信息是本平台的一项基本原则，本平台将会采取合理的措施保护您的个人信息。
		</div>
        <div class="content">
            6.2 除法律法规规定或为实现本服务目的外（例如：为招募活动志愿者，活动组织机构可以查询您向本平台提供的注册资料等），未经您的许可本平台不会向第三方公开、透露您的个人信息。本平台对相关信息采用专业加密存储与传输方式，保障您个人信息的安全。
		</div>
        <div class="content">
            6.3 您在使用本服务的过程中，需要提供一些必要的信息，例如：在账户注册环节，需要您提供微信昵称、微信头像；在志愿服务活动报名环节，需要您填写性别、年龄、手机号码、身份证号；在志愿服务活动汇报环节，需要您提交活动相关的文字或图片等。若国家法律法规或政策有特殊规定的，您需要提供真实的身份信息，您必须保证您所提供的所有信息真实、准确、完整、有效。若您提供的信息不完整、不准确的，则无法使用本服务或在使用过程中受到限制。
		</div>
        <div class="content">
            6.4 本平台将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。
		</div>
        <div class="content">
            6.5 未经您的同意，本平台不会向本平台以外的任何公司、组织和个人披露您的个人信息，但法律法规另有规定的除外。
		</div>
        <div class="content">
            6.6 腾讯非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用腾讯的服务前，应事先取得您家长或法定监护人的书面同意。
		</div>
        <div class="title">
			七、知识产权声明
		</div>
        <div class="content">
            7.1 本平台是本服务的知识产权权利人。本服务的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本服务相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，腾讯享有上述知识产权，但相关权利人依照法律规定应享有的权利除外。
		</div>
        <div class="content">
            7.2 未经本平台或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。
		</div>
        <div class="title">
            八、服务中止或终止
		</div>
        <div class="content">
            8.1 您理解并同意，基于用户体验、浙工大志愿者之家或相关服务平台运营安全、平台规则要求等综合因素，本平台有权选择提供服务的对象，有权决定功能设置，有权决定功能开放、数据接口和相关数据披露的对象和范围。针对以下情形，有权视具体情况中止或终止提供本服务，包括但不限于：
		</div>
        <div class="content">
            8.1.1 违反法律法规或本协议规定的；
		</div>
        <div class="content">
            8.1.2 影响服务体验的；
		</div>
        <div class="content">
            8.1.3 存在安全隐患的；
		</div>
        <div class="content">
            8.1.4 与浙工大志愿者之家小程序已有主要功能或功能组件相似、相同，或可实现上述功能或功能组件的主要效用的；
		</div>
        <div class="content">
            8.1.5 界面、风格、功能、描述或使用者体验与浙工大志愿者之家小程序类似，可能造成腾用户认为其所使用的功能或服务来源于浙江工业大学授权的；
		</div>
        <div class="content">
            8.1.6 违背浙工大志愿者之家平台运营原则，或不符合浙工大志愿者之家其他管理要求的。
		</div>
         <div class="content">
            8.2 您理解并同意,本平台有权在无需通知用户的情况下中止或终止本服务。若由此给您造成损失的，您同意放弃追究本平台的责任。
		</div>
         <div class="content">
            8.3 您理解并同意，本协议约定的其他中止或终止条件发生或实现的，本平台有权随时中止或终止向您提供全部或部分本服务。
		</div>
         <div class="content">
            8.4 您理解并同意，如本协议或本服务因为任何原因终止的，对于您账号中的全部数据或您因使用本服务而存储在本平台服务器中的数据等任何信息，本平台可将该等信息保留或删除，包括服务终止前您尚未完成的任何数据，您同意不追究本平台的任何责任或不向本平台主张任何权利。
		</div>
         <div class="title">
            九、关于通知
		</div>
         <div class="content">
            9.1 本平台可能会以电子邮箱、手机短信、用户注册的本服务帐户的管理系统内发送站内信等方式中的一种或多种，向您送达关于本服务的各种规则、通知、提示等信息，该等信息一经本平台采取前述任何一种方式公布或发送，即视为您已经接受并同意，对用户产生约束力。若您不接受的，请您书面通知本平台，否则视为您已经接受、同意。
		</div>
        <div class="content">
            9.2 若由于您提供的电子邮箱、手机号码等信息错误，导致您未收到相关规则、通知、提示等信息的，您同意仍然视为您已经收到相关信息并受其约束，一切后果及责任由您自行承担。
		</div>
         <div class="content">
            9.3 您应当按照本服务对外正式公布的联系方式书面联系本平台。
		</div>
         <div class="title">
            十、第三方软件或技术
		</div>
         <div class="content">
            10.1 本服务可能会使用第三方软件或技术（包括本服务可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。
		</div>
         <div class="content">
            10.2 本服务如果使用了第三方的软件或技术，本平台将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本服务安装包特定文件夹中打包、或通过开源软件页面等形式进行展示，它们可能会以“软件使用许可协议”、“授权协议”、“开源代码许可证”或其他形式来表达。前述通过各种形式展现的相关协议、其他文件及网页，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守这些要求。如果您没有遵守这些要求，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求腾讯给予协助，您应当自行承担法律责任。
		</div>
         <div class="content">
            10.3 如因本服务使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，本平台不承担任何责任。本平台不对第三方软件或技术提供客服支持，若您需要获取支持，请与第三方联系。
		</div>
        <div class="title">
            十一、其他
		</div>
         <div class="content">
            11.1 您使用本服务即视为您已阅读并同意受本协议的约束。本平台有权在必要时修改本协议条款。您可以在本服务的最新版本中查阅相关协议条款。本协议条款变更后，如果您继续使用本服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用本服务。
		</div>
         <div class="content">
            11.2 本协议签订地为中华人民共和国浙江省省杭州市上城区。
		</div>
         <div class="content">
            11.3 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
		</div>
         <div class="content">
            11.4 若您和本平台之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。
		</div>
         <div class="content">
            11.5 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
		</div>
        <div class="content">
            11.6 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
		</div>
         <div class="content">
            11.7 任何个人、组织和机构发现本平台用户存在任何违反法律规定或平台规定的，可向本平台官方客服留言举报，本平台依据本协议约定获得处理违法违规内容的权利，该权利不构成本平台的义务或承诺，本平台不能保证及时发现违法行为或进行相应处理。
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		mounted() { //实例被挂载后调用
			let that = this;
			let erd = elementResizeDetectorMaker()
			erd.listenTo(document.getElementById("divport"), function(element) {
				//监听div宽度变化
				let width = element.offsetWidth
		 	let height = element.offsetHeight
				console.log(width)
				console.log(height)
				that.$nextTick(function() {
					let fontSize = width / 80 + 'px';
					let html = document.documentElement;
					html.style.fontSize = fontSize;
					console.log('fontSize', html.style.fontSize)
				})
			})
		},
		created() {},
		methods: {

		}
	}
</script>

<style scoped="">
	html {
		/*7.5为设计稿的宽度*/
		font-size: -webkit-calc(100vw/7.5);
		/*此时定义了在750的设计稿下  1rem=100px*/
	}
    .text-c{
        text-align:center;
    }
	body {
		font-size: 0.24rem;
	}

	.container {
		padding: 0.3rem;
	}

	.content {
		font-size: 0.6rem;
		line-height: 1.0rem;
		word-break: break-all;
	}

	.title {
		font-size: 0.9rem;
		margin: 0.2rem 0;
		font-weight: 600;
	}
</style>
